
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HRMService from '@/services/hrm-service'
@Component({

})
export default class PayrollSlip extends Vue {
  @Prop({ type: Number, }) id!: number;
  @Prop({ type: Object }) payload!: any;

  item: any = {}
  emp_item: any = {}
  mappingFeilds: any = {};
  monthly_addition_fields: any = [];
  monthly_deduction_fields: any = [];
  non_monthly_addition_fields: any = [];
  non_monthly_deduction_fields: any = [];

  mounted() {
    // console.log(this.id)
  }
  created() {
    HRMService.getPayrollConfig().then((resp) => {
      this.mappingFeilds = resp.data[0].fields_mapping;
      // console.log(this.mappingFeilds);
      for (const key in this.mappingFeilds) {
        const fieldData = this.mappingFeilds[key];
        if (fieldData.status === true || fieldData.status === "true") {
          if (key.startsWith("monthly_addition")) {
            this.monthly_addition_fields.push({
              label: fieldData.name,
              key: key,
            });
          } else if (key.startsWith("monthly_deduction")) {
            this.monthly_deduction_fields.push({
              label: fieldData.name,
              key: key,
            });
          } else if (key.startsWith("non_monthly_addition")) {
            this.non_monthly_addition_fields.push({
              label: fieldData.name,
              key: key,
            });
          } else if (key.startsWith("non_monthly_deduction")) {
            this.non_monthly_deduction_fields.push({
              label: fieldData.name,
              key: key,
            });
          }
        }

      }
    });
  }

  @Watch("id")
  onIdChanged(newValue: any, oldValue: any) {
    this.get_data(this.id, this.payload);

  }
  loading = false
  get_data(id: any, payload: any) {
    this.loading = true;
    HRMService.employee_payroll_by_date(id, payload)
      .then((response) => {
        this.item = response.data.result;

      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });

    HRMService.get_employee(id)
      .then((response) => {
        this.emp_item = response.data["employee"];
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
}
